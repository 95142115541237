import React, { useState, useEffect } from 'react';
import axios from 'axios';

//const API_URL = 'http://localhost:6060';
const API_URL = 'https://addatabridge.co.uk/habitapi';

function App() {
    const [sprints, setSprints] = useState([]);
    const [habits, setHabits] = useState([]);
    const [selectedSprint, setSelectedSprint] = useState('');
    const [overview, setOverview] = useState([]);
    const [data, setData] = useState([]);
    const [category, setCategory] = useState('');
    const [view, setView] = useState('overview');

    const [newSprint, setNewSprint] = useState({ name: '', start_date: '', end_date: '' });
    const [newHabit, setNewHabit] = useState({ name: '', category: '', target: 0 });
    const [assignHabit, setAssignHabit] = useState({ sprint_id: '', habit_id: '' });

    const fetchSprints = async () => {
        const response = await axios.get(`${API_URL}/sprints`);
        setSprints(response.data);
    };

    const fetchHabits = async () => {
        const response = await axios.get(`${API_URL}/habits`);
        setHabits(response.data);
    };

    const fetchOverview = async (sprintId) => {
        if (!sprintId) {
            setOverview([]);
            return;
        }
        const response = await axios.get(`${API_URL}/overview?sprint_id=${sprintId}`);
        console.log(response.data);
        setOverview(response.data);
    };

    const createSprint = async () => {
        await axios.post(`${API_URL}/sprints`, newSprint);
        fetchSprints();
    };

    const createHabit = async () => {
        await axios.post(`${API_URL}/habits`, newHabit);
        fetchHabits();
    };

    const assignHabitToSprint = async () => {
        await axios.post(`${API_URL}/sprint-habits`, assignHabit);
        fetchOverview(selectedSprint); // Refresh overview for the current sprint
    };

    const addEntry = async (habitId, value) => {
        await axios.post(`${API_URL}/entries`, {
            habit_id: habitId,
            date_time: new Date().toISOString(),
            value,
        });
        fetchOverview(selectedSprint); // Refresh habits after adding entry
    };

    function dataUpdate() {
        if (category) {
            console.log(overview);
            const filteredData = overview.filter((habit) => habit.category === category);
            setData(filteredData);
        } else {
            setData(overview);
        }

    }

    useEffect(() => {
        dataUpdate();
    }, [category]);

    useEffect(() => {
        dataUpdate();
    }, [overview]);

    useEffect(() => {
        fetchSprints();
        fetchHabits();
    }, []);

    return (
        <div style={{ width: '98%' }}>
            
                <div>
                    <button onClick={() => setView('admin')}>Admin Panel</button>
                    <button onClick={() => setView('overview')}>Sprint Overview</button>

                    {view === 'admin' ? (
                        newFunction(newSprint, setNewSprint, createSprint, newHabit, setNewHabit, createHabit, assignHabit, setAssignHabit, sprints, habits, assignHabitToSprint)
                    ) : (
                        newFunction_1(selectedSprint, setSelectedSprint, fetchOverview, sprints, setCategory, habits, data, addEntry)
                    )}
                </div>
            
        </div>
    );
}

export default App;

function newFunction_1(selectedSprint, setSelectedSprint, fetchOverview, sprints, setCategory, habits, data, addEntry) {
    return <div>
        <h2>Sprint Overview</h2>

        <select
            value={selectedSprint}
            onChange={(e) => {
                setSelectedSprint(e.target.value);
                fetchOverview(e.target.value);
            } }
        >
            <option value="">Select a Sprint</option>
            {sprints.map((sprint) => (
                <option key={sprint.id} value={sprint.id} selected={selectedSprint === sprint.id}>
                    {sprint.name}
                </option>
            ))}
        </select>

        <select
            onChange={(e) => {
                const category = e.target.value;
                setCategory(category);
            } }
        >
            <option value="">All Categories</option>
            {Array.from(new Set(habits.map(habit => habit.category))).map((category) => (
                <option key={category} value={category}>
                    {category}
                </option>
            ))}
        </select>


        {data.length > 0 ? (
            <div>

                <ul>
                    {data.map((habit) => (
                        <li key={habit.habit_id}>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '80%',
                                }}
                            >
                            {habit.habit_name} 
                            <div>
                                <button onClick={() => addEntry(habit.habit_id, 1)}>+1</button>
                                <button onClick={() => addEntry(habit.habit_id, 10)}>+10</button>
                            </div>
                            </div>

                            {/*<strong>Target:</strong> {habit.target} <br />
                            <strong>Current:</strong> {habit.current_value || 0} <br />
                            */}
                            <div
                                style={{
                                    width: '80%',
                                    backgroundColor: '#f3f3f3',
                                    borderRadius: '5px',
                                    overflow: 'hidden',
                                    margin: '5px 0',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div
                                    style={{
                                        width: `${Math.min(
                                            (habit.current_value / habit.target) * 100,
                                            100
                                        )}%`,
                                        backgroundColor: '#4caf50',
                                        height: '20px',
                                        textAlign: 'center',
                                        color: 'white',

                                    }}
                                >
                                    {Math.min(
                                        ((habit.current_value || 0) / habit.target) * 100,
                                        100
                                    ).toFixed(1)}
                                    %
                                </div>


                            </div>

                                                    </li>
                    ))}
                </ul>
            </div>
        ) : (
            selectedSprint && <p>No habits assigned to this sprint yet.</p>
        )}
    </div>;
}

function newFunction(newSprint, setNewSprint, createSprint, newHabit, setNewHabit, createHabit, assignHabit, setAssignHabit, sprints, habits, assignHabitToSprint) {
    return <div>
        <h2>Admin Panel</h2>
        <h3>Create Sprint</h3>
        <input
            type="text"
            placeholder="Sprint Name"
            value={newSprint.name}
            onChange={(e) => setNewSprint({ ...newSprint, name: e.target.value })} />
        <input
            type="date"
            value={newSprint.start_date}
            onChange={(e) => setNewSprint({ ...newSprint, start_date: e.target.value })} />
        <input
            type="date"
            value={newSprint.end_date}
            onChange={(e) => setNewSprint({ ...newSprint, end_date: e.target.value })} />
        <button onClick={createSprint}>Create Sprint</button>

        <h3>Create Habit</h3>
        <input
            type="text"
            placeholder="Habit Name"
            value={newHabit.name}
            onChange={(e) => setNewHabit({ ...newHabit, name: e.target.value })} />
        <input
            type="text"
            placeholder="Category"
            value={newHabit.category}
            onChange={(e) => setNewHabit({ ...newHabit, category: e.target.value })} />
        <input
            type="number"
            placeholder="Target Value"
            value={newHabit.target}
            onChange={(e) => setNewHabit({ ...newHabit, target: parseInt(e.target.value) })} />
        <button onClick={createHabit}>Create Habit</button>

        <h3>Assign Habit to Sprint</h3>
        <select
            value={assignHabit.sprint_id}
            onChange={(e) => setAssignHabit({ ...assignHabit, sprint_id: e.target.value })}
        >
            <option value="">Select Sprint</option>
            {sprints.map((sprint) => (
                <option key={sprint.id} value={sprint.id}>
                    {sprint.name}
                </option>
            ))}
        </select>
        <select
            value={assignHabit.habit_id}
            onChange={(e) => setAssignHabit({ ...assignHabit, habit_id: e.target.value })}
        >
            <option value="">Select Habit</option>
            {habits.map((habit) => (
                <option key={habit.id} value={habit.id}>
                    {habit.name}
                </option>
            ))}
        </select>
        <button onClick={assignHabitToSprint}>Assign Habit</button>
    </div>;
}

